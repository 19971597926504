import demoStr from "@assets/strings/request_demo.json"
import homeStr from "@assets/strings/home.json"
import { MouseEventHandler } from 'react'
import { Languages } from 'utils/datautils'
import styles from '../styles/RequestDemo.module.scss'

type ReqDemoProps = {
  lang: Languages
  onModalControl: MouseEventHandler<HTMLButtonElement>
  requestDemoClassType: string
}
/**
 * 
 * @param lang to switch between languages 
 * @returns 
 * @param onModalControl to notify modal control event to show up the modal
 */
export default function RequestDemo({lang, onModalControl, requestDemoClassType}: ReqDemoProps) {
  const renderText = () => {
    const renderer = (
      <><span>Kami memahami bisnis dan tantangan Anda.</span> Dapatkan solusi terbaik bagi kepatuhan hukum perusahaan Anda sekarang.</>
    )
    return renderer;
  };

  return (
    <div
      className={styles.request_section} 
      onClick={(click) => {
        click.preventDefault()
        click.stopPropagation()
        return onModalControl
      }}
    >
      <div>
        <div className={styles.request_section_subtitle} dangerouslySetInnerHTML={{__html: demoStr.subtitle[lang] }}></div>
        <button className={`${styles.request_section_btn} ${styles.mobile} ${requestDemoClassType}`} onClick={onModalControl}>{homeStr.requestDemo[lang]}</button>
        <button className={`${styles.request_section_btn} ${styles.web} ${requestDemoClassType}`} onClick={onModalControl}>{demoStr.requestDemo[lang]}</button>
      </div>
    </div>
  )
}