import Link from "next/link";
import { useEffect, useState } from "react";
import navStr from "@assets/strings/navbar.json";
import { Languages } from "../utils/datautils";

import styles from '../styles/Navbar.module.css';
import { useRouter } from "next/router";
``
type MainProps = {
  lang: Languages,
  onLangChange: Function,
  onRequestDemo: Function
}

export default function MainNavbar(props: MainProps) {
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [requestDisplay, setRequestDisplay] = useState(true);
  const [aboutDisplay, setAboutDisplay] = useState(false);
  const [langDisplay, setLangDisplay] = useState(false);
  /** to do consider dispatcher */
  const [language, setLanguage] = useState(Languages.Bahasa);
  const [languageList] = useState([
    {
      name: 'EN',
      value: Languages.English
    },
    {
      name: 'ID',
      value: Languages.Bahasa
    }
  ]);
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  useEffect(() => {
    props.onLangChange(language)
  }, [language]);

  useEffect(() => {
    locale === 'id' ? setLanguage(Languages.Bahasa) : setLanguage(Languages.English)
    locale === 'id' ? handleChangeLanguage('id') : handleChangeLanguage('en')
  }, [locale]);

  const showMenu = () => {
    setMenuDisplay(!menuDisplay)
    setRequestDisplay(!requestDisplay)
  };

  const handleChangeLanguage = async (lang: string) => {
    try {
      setLangDisplay(false);
      (lang == 'id') ? setLanguage(Languages.Bahasa) : setLanguage(Languages.English)
      router.push({ pathname, query }, asPath, { locale: lang })
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <nav id="navigation-logo" className={styles.site_nav}>
      <div className="grid container">
        <Link href="/">
          <a>
            <img alt="main logo" className={styles.nav_logo} src="/assets/rcs_logo.png"/>
          </a>
        </Link>

        {/* Mobile Version start*/}
        <div className={`${styles.nav_menu} flex`}>
          {
            requestDisplay ?
              <button
                className={`${styles.nav_demo}`}
                onClick={() => props.onRequestDemo()}
              >{language === 'id' ? navStr.requestDemo[Languages.Bahasa] : navStr.requestDemo[Languages.English]}</button>
            :
            ''
          }

          <div className={styles.spannable + styles.min}>
            <img
              alt="navigation menu"
              src={menuDisplay ? "/assets/close.svg":"/assets/menu_navigation_icon.svg" }
              onClick={() => {
                showMenu()
              }}
            />
          </div>
        </div>

        {
          menuDisplay ?
          <span className={styles.dropdown_full}>
            <Link href="/">
              <a className={styles.nav_title} onClick={showMenu}>{navStr.home[language]}</a>
            </Link>

            <Link href="/about">
            <a className={styles.nav_title} onClick={showMenu} >{navStr.aboutRcs[language]}</a>
            </Link>

            <Link href="/use-case">
              <a className={styles.nav_title} onClick={showMenu} >{navStr.useCase[language]}</a>
            </Link>
            
            <Link href="/insights">
              <a className={styles.nav_title} onClick={showMenu}>{navStr.articles[language]}</a>
            </Link>

            <Link href="/faq">
              <a className={styles.nav_title} onClick={showMenu}>{navStr.faqs[language]}</a>
            </Link>

            {/* Button Lang Mobile */}
            <div className={styles.site_nav_language}>
              {
                languageList.map((lang, index) => {
                  return (
                    <span
                      key={index}
                      style={{ borderTopLeftRadius: index === 0 ? '10px' : '0px', borderBottomLeftRadius: index === 0 ? '10px' : '0px', borderTopRightRadius: index === languageList.length -1 ? '10px' : '0px', borderBottomRightRadius: index === languageList.length -1 ? '10px' : '0px', backgroundColor: language === lang.value ? '#F8CA0F' : '#FFF', color: language === lang.value ? '#003366' : '#777', padding: 10, cursor: 'pointer', fontWeight: 700 }}
                      onClick={() => {
                        if (window.location.pathname.includes('/insights/') || window.location.pathname.includes('/use-case/')) {
                          router.push('/', "", { locale: lang.name.toLocaleLowerCase() === 'id' ? 'id' : 'en'})
                        } else {
                          handleChangeLanguage(lang.name.toLocaleLowerCase() === 'id' ? 'id' : 'en')
                        }
                      }}
                    >
                      {lang.name}
                    </span>
                  )
                })
              }
            </div>
            <a>
              <button
                onClick={() => {
                  props.onRequestDemo();
                  showMenu()
                }}
                className="free"
              >
                {navStr.requestDemo[language]}
              </button>
            </a>
          </span>
          :
          ''
        }
        {/* Mobile Version end*/}

        {/* Web Version start*/}
        <ul className={styles.nav_desktop}>
          <li
            className={`${styles.spannable} ${styles.nav_list_box} ${styles.nav_fit_content}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAboutDisplay(!aboutDisplay);
            }}
            style={{ minWidth: '0px' }}
          >
            <Link href="/about">
              <a
                className={styles.nav_title}
                // onClick={gotoAbout}
              >
                {navStr.aboutRcs[language]}
              </a>
            </Link>
          </li>

          <Link href="/use-case">
            <a className={styles.nav_list_box} style={{ minWidth: '0px' }}>
              <li className={styles.nav_title}>{navStr.useCase[language]}</li>
            </a>
          </Link>

          <Link href="/insights">
            <a className={styles.nav_list_box}>
              <li className={styles.nav_title}>{navStr.articles[language]}</li>
            </a>
          </Link>

          <Link href="/faq">
            <a className={`${styles.nav_list_box}`} style={{ minWidth: '0px' }}>
              <li className={styles.nav_title}>{navStr.faqs[language]}</li>
            </a>
          </Link>

          <li className={styles.custom_list_container}>
            {/* Lang Button */}
            <div className={styles.switch_language_container} onClick={() => setLangDisplay(!langDisplay)}>
              {/* <img alt="navigation globe" src="/assets/globe.svg" onClick={() => setLangDisplay(!langDisplay)} className={styles.global_icon} /> */}
              {
                langDisplay ?
                <>
                  <div className={styles.triangle_up}></div>
                  <span className={styles.language_list__box}>
                    <div
                      className={styles.language}
                      onClick={() => {
                        if (window.location.pathname.includes('/insights/') || window.location.pathname.includes('/use-case/')){
                          router.push('/', "", { locale: 'id' });
                          setLangDisplay(false);
                        } else {
                          handleChangeLanguage('id')
                        }
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
                        <img src="/assets/bahasa_icon.png" style={{ width: '20px', height: 'auto', objectFit: 'contain', marginLeft: 0 }}/>
                        <a >Bahasa</a>
                      </div>
                    </div>
                    <div
                      className={styles.language}
                      style={{ marginTop: '8px' }}
                      onClick={() => {
                        if (window.location.pathname.includes('/insights/') || window.location.pathname.includes('/use-case/')) {
                          router.push('/', "", { locale: "en" })
                          setLangDisplay(false);
                        } else {
                          handleChangeLanguage('en')
                        }
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
                        <img src="/assets/english_icon.png" style={{ width: '20px', height: 'auto', objectFit: 'contain', marginLeft: 0 }}/>
                        <a >English</a>
                      </div>󠁧󠁢
                    </div>
                  </span>
                </>
                :
                ''
              }
            </div>
            <div>
              <button className="gtm-requestDemo-menu" onClick={() => props.onRequestDemo()}>{navStr.requestDemo[language]}</button>
            </div>
          </li>


        </ul>
        {/* Web Version end*/}
      </div>
    </nav>
  );
};