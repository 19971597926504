import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Languages } from "utils/datautils";
import styles from "../styles/FloatWhatsapp.module.css";
import strings from "@assets/strings/float_whatsapp.json";

type MainProps = {
  lang: Languages;
};

export default function FloatWhatsapp(props: MainProps) {
  const [waUrl, setWaUrl] = useState<string>("/wa");

  useEffect(() => {
    if (typeof window !== undefined) {
      setWaUrl(`/wa${window.location.search}`);
    }

    return () => { }

  }, [props]);

  return (
    <Link href={waUrl} locale={props.lang === 'id' ? 'id' : 'en'} passHref>
      <a target="_blank" rel="noopener noreferrer">
        <div className={styles.button_whatsapp}>
          <div className={styles.wa_description}>
            <div>{strings?.title[props.lang]}</div>
          </div>
          <div>
            <div className={styles.wa_img}>
              <img src="/assets/wa_logo.png" alt="whatsapp contact" />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
